import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom"
import Layout from "../components/frontend/Layout"
import Services from "../pages/Services"
import Pricing from "../pages/Pricing"
import Home from "../pages/Home"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="" element={<Home />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="services" element={<Services />} />
    </Route>
  )
)

export default router