import { hideDialog, toastError } from "../../../redux/slice/other.slice"
import { getDetailsPricingReq } from "../../../redux/slice/mail.slice"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../../../assets/js/MuiIcon"
import CustomInput from "../CustomInput"
import {
  Box, Button, CircularProgress, DialogContent,
  DialogTitle, Divider, Grid2 as Grid,
  IconButton, Paper, styled, Typography
} from "@mui/material"

const Div = styled("div")(() => ({}))

function GetDetailsPeicing() {
  const dispatch = useDispatch()
  const data = useSelector(state => state.mailSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    formData.industry = data?.industryData?.industry
    formData.priceRange = data?.industryData?.priceRange
    if (formData?.fullName === "") {
      dispatch(toastError("Name is required!"))
      return
    }
    if (formData?.email === "") {
      dispatch(toastError("Email is required!"))
      return
    }
    if (formData?.description === "") {
      dispatch(toastError("Description is required!"))
      return
    }

    dispatch(getDetailsPricingReq(formData))
  }
  const handleClose = () => {
    dispatch(hideDialog())
  }

  return (
    <Paper id="ShareWorksapce">
      <DialogTitle component="div" className="p-2 relative">
        <Box className=" inline-block absolute top-[50%] right-[10px] translate-y-[-50%]">
          <IconButton
            size="small"
            onClick={handleClose}
          ><MuiIcons.Close
              className="text-deluge-600" />
          </IconButton>
        </Box>
        <Typography
          component="h6"
          sx={{
            fontSize: "24px",
            color: "#d6422e",
            fontWeight: "500",
            fontFamily: "Jost",
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >fill the details
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Div as="form" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid size={12}>
              <CustomInput
                type="text"
                name="fullName"
                label={<>Name<span className="text-punch-500">*</span></>}
              />
            </Grid>
            <Grid size={12}>
              <CustomInput
                type="email"
                name="email"
                label={<>Email<span className="text-punch-500">*</span></>}
              />
            </Grid>
            <Grid size={12}>
              <CustomInput
                type="text"
                name="phoneNumber"
                label="Phone Number (optional)"
              />
            </Grid>
            <Grid size={12}>
              <CustomInput
                type="textarea"
                name="description"
                label={<>Description<span className="text-punch-500">*</span></>}
              />
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  color: "#000c",
                  fontSize: "12px",
                  fontFamily: "Poppins"
                }}
              ><span className="font-medium">Note:</span> Input fields with * are required!
              </Typography>
            </Grid>
            <Grid size={12} sx={{ textAlign: "right", mt: 1 }}>
              <Button
                sx={{
                  py: "4px",
                  fontSize: "16px",
                  fontWeight: "400",
                  minWidth: "150px",
                  boxShadow: "none",
                  bgcolor: "#d6422e",
                  fontFamily: "Jost",
                  borderRadius: "50px",
                  textTransform: "capitalize"
                }}
                type="submit"
                variant="contained"
              // onClick={handleCalculation}
              >Submit
                {data?.loading &&
                  <CircularProgress
                    sx={{
                      ml: "4px",
                      color: "white",
                      display: "flex"
                    }}
                    size={20}
                    thickness={5}
                  />
                }
              </Button>
            </Grid>
          </Grid>
        </Div>
      </DialogContent>
    </Paper>
  )
}

export default GetDetailsPeicing