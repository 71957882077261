import { RouterProvider } from "react-router-dom"
import { ThemeProvider } from "@mui/material"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import router from "./routes/routes"
import theme from "./utils/muiTheme"
import store from "./redux/store"
import "./assets/css/style.css"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>
)