import { getinTouchReq, handleSubscribeReq, setStatus } from "../redux/slice/mail.slice"
import { services, experiences, industries, testimonials } from "../constant/frontend/home"
import { showDetailDialog, toastError } from "../redux/slice/other.slice"
import CustomInput from "../components/common/CustomInput"
import { useDispatch, useSelector } from "react-redux"
import MuiIcons from "../assets/js/MuiIcon"
import { Fragment, useState } from "react"
import Images from "../assets/images"
import {
  Box,
  Button, Collapse, Container,
  Grid2 as Grid, Input, Paper,
  Skeleton, styled, Typography
} from "@mui/material"

const Div = styled("div")(() => ({}))
const Image = styled("img")(() => ({}))
const Para = styled("p")(() => ({}))
const Span = styled("span")(() => ({}))

function Home() {
  const dispatch = useDispatch()
  const [imgSkell1, setImgSkell1] = useState(true)
  const [imgSkell2, setImgSkell2] = useState(true)
  const [imgSkell3, setImgSkell3] = useState(true)
  const [calculation, setCalculation] = useState({})
  const varified = sessionStorage.getItem("varified")
  const [industry, setIndustry] = useState(industries[0])
  const mailData = useSelector(state => state.mailSlice)

  const handleGetInTouch = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if (formData?.fullName === "") {
      dispatch(toastError("Full Name is required!"))
      return
    }
    if (formData?.email === "") {
      dispatch(toastError("Email is required!"))
      return
    }
    dispatch(getinTouchReq(formData))
  }

  const handleSubscription = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if (formData?.email === "") {
      dispatch(toastError("Email is required!"))
      return
    }
    dispatch(handleSubscribeReq(formData))
  }

  const handleTransactionSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    const transactionValue = parseInt(formData?.transactions)
    if (!transactionValue || transactionValue < 0) {
      dispatch(toastError("Transaction value is required!"))
      return
    }

    const priceRange = handleCalculation(transactionValue)
    if (varified === "true") {
      dispatch(setStatus())
      return
    }
    dispatch(showDetailDialog({ industry: industry?.name, priceRange }))
  }

  const handleCalculation = (transactions) => {
    const basePrice = transactions * industry.rate
    let rangeMin = +basePrice.toFixed(2)
    let rangeMax = rangeMin + (rangeMin * 10) / 100
    const minRange = `$${industry?.minPrice} - $${Math.floor((industry?.minPrice + (industry?.minPrice * 10) / 100))}`
    setCalculation({ rangeMin, rangeMax, minRange })
    const priceRange = `$${rangeMin} - $${rangeMax}`
    return priceRange
  }

  return (
    <Fragment>
      <Box component="section" sx={{ pb: "50px", minHeight: "354px", position: "relative" }}>
        {imgSkell1 &&
          <Skeleton
            sx={{
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "unset",
              position: "absolute",
            }}
          />
        }
        <Image
          sx={{
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            display: !imgSkell1 ? "block" : "none"
          }}
          alt="banner-img"
          src={Images.banner_1}
          onLoad={() => { setImgSkell1(false) }}
        />
        <Box
          sx={{
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            position: "absolute",
            background: "#0000007d"
          }}
        />

        <Container
          maxWidth="xl"
          sx={{
            zIndex: "10",
            position: "relative",
          }}
        >
          <Box sx={{ p: "34px", textAlign: { xs: "center", md: "start" } }}>
            <Grid container spacing={3} sx={{ justifyContent: { xs: "center", md: "start" } }}>
              <Grid size={{ xs: "12", md: 8 }}>
                <Box
                  sx={{
                    gap: "20px",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "34px",
                      fontWeight: "600",
                      lineHeight: "1.2",
                      fontFamily: "Poppins"
                    }}
                  >Unlock the Power of <br /> Finance and Accounting Outsourcing <br />for Your Business
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: "12", md: 4 }}>
                <Paper
                  sx={{
                    px: 2,
                    py: 3,
                    mx: "auto",
                    maxWidth: "300px",
                    boxShadow: "none",
                    borderRadius: "20px"
                  }}
                  component="form"
                  onSubmit={handleGetInTouch}
                >
                  <Grid container spacing={2}>
                    <Grid size={12}>
                      <Typography
                        component="h4"
                        className="sec-title !mb-0"
                      >Get in touch
                      </Typography>
                    </Grid>
                    <Grid size={12}>
                      <CustomInput
                        type="text"
                        label="Name"
                        name="fullName"
                      />
                    </Grid>
                    <Grid size={12}>
                      <CustomInput
                        type="email"
                        label="Email"
                        name="email"
                      />
                    </Grid>
                    <Grid size={12}>
                      <CustomInput
                        type="text"
                        name="phoneNumber"
                        label="Phone Number (optional)"
                      />
                    </Grid>
                    <Grid size={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          py: 0,
                          width: "100%",
                          fontSize: "16px",
                          fontWeight: "400",
                          boxShadow: "none",
                          fontFamily: "Jost",
                          bgcolor: "#d6422e",
                          borderRadius: "50px",
                          textTransform: "capitalize"
                        }}
                      >submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Calculater */}
      <Box id="calculater" component="section" sx={{ mt: "-50px", zIndex: "20", position: "relative" }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 3,
              mx: "auto",
              maxWidth: "800px",
              bgcolor: "white",
              borderRadius: "20px",
              boxShadow: "0 5px 50px 10px #0000000f",
            }}
          >
            <Typography
              component="h5"
              className="sec-title !mb-0"
            >Calculator: Monthly Accounting Price</Typography>
            <Typography
              sx={{
                mt: 1,
                mb: 3,
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "15px !important",
                fontWeight: "400 !important",
              }}
            >Select your industry and add monthly transactions count
            </Typography>
            <Box>
              <Grid container columnSpacing={3} rowSpacing={{ xs: 2, md: 0 }}>
                <Grid size={{ xs: 12, md: 8 }}>
                  <Box>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      {industries?.map((indus, i) => (
                        <Grid key={i} size={{ xs: 12, sm: 6, lg: 4 }}>
                          <Box
                            sx={{
                              px: 2,
                              py: 1,
                              height: "100%",
                              display: "grid",
                              lineHeight: "1",
                              fontSize: "16px",
                              fontWeight: "400",
                              cursor: "pointer",
                              borderRadius: "6px",
                              border: "1px solid",
                              position: "relative",
                              fontFamily: "Poppins",
                              alignContent: "center",
                              textTransform: "capitalize",
                              color: indus?.name === industry?.name && "white",
                              bgcolor: indus?.name === industry?.name && "#d6422e",
                              borderColor: indus?.name !== industry?.name ? "#C9C9C9" : "transparent"
                            }}
                            onClick={() => setIndustry(indus)}
                            value={indus?.value}
                          >{indus?.name}
                            {indus?.name === industry?.name &&
                              < MuiIcons.FillCheck
                                sx={{
                                  top: "4px",
                                  right: "4px",
                                  position: "absolute",
                                  fontSize: "16px",
                                }}
                              />
                            }
                          </Box>
                        </Grid>
                      ))
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: { xs: 2, md: "8px" },
                      justifyContent: "space-between",
                    }}
                    as="form"
                    onSubmit={handleTransactionSubmit}
                  >
                    <Para
                      sx={{
                        fontSize: "17px",
                        fontWeight: "500",
                        lineHeight: "1.1",
                        textAlign: "center",
                      }}
                    >Number of Transactions  (monthly)
                    </Para>
                    <Input
                      sx={{
                        p: "0 6px",
                        display: "block",
                        borderRadius: "8px",
                        border: "1px solid #C9C9C9",
                        ":after": { display: "none" },
                        ":before": { display: "none" },

                        "input": {
                          fontSize: "15px",
                          fontFamily: "Poppins",
                          "&::placeholder": {
                            opacity: 1,
                            fontSize: "14px",
                            color: "#666666",
                            fontWeight: "400",
                            fontFamily: "Jost",
                            textAlign: "center",
                          },
                        }
                      }}
                      name="transactions"
                      placeholder="Enter the number of transaction"
                    />
                    <Button
                      sx={{
                        py: 0,
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: "400",
                        boxShadow: "none",
                        fontFamily: "Jost",
                        bgcolor: "#d6422e",
                        borderRadius: "50px",
                        textTransform: "capitalize"
                      }}
                      type="submit"
                      variant="contained"
                    >Calculate
                    </Button>
                  </Box>
                </Grid>

                {Object.keys(calculation).length > 0 &&
                  <Collapse
                    sx={{ width: "100%" }}
                    in={mailData?.status === 200 && varified === "true"}
                  >
                    <Grid size={{ xs: 12 }}>
                      <Box
                        sx={{
                          p: 2,
                          mt: 1,
                          bgcolor: "#fde6e3",
                          borderRadius: "6px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            textTransform: "capitalize"
                          }}
                        >Industry : {industry?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            textTransform: "capitalize"
                          }}
                          className="text-emerald-500"
                        >Price range :&nbsp;
                          {(industry?.minPrice > calculation?.rangeMin) ?
                            calculation?.minRange :
                            `$${calculation?.rangeMin} - $${calculation?.rangeMax}`
                          }
                        </Typography>
                        {industry?.minPrice > calculation?.rangeMin &&
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1.5,
                              fontFamily: "Poppins",
                              textTransform: "capitalize"
                            }}
                          >NOTE: This is our minimum price based on the transaction volume you have entered : {calculation?.minRange}
                          </Typography>
                        }
                      </Box>
                    </Grid>
                  </Collapse>
                }
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* About */}
      <Box component="section" sx={{ py: 3 }}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ height: "250px" }}>
                {imgSkell2 &&
                  <Skeleton
                    sx={{
                      height: "100%",
                      transform: "unset"
                    }}
                  />
                }
                <Image
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: !imgSkell2 ? "block" : "none"
                  }}
                  alt="img"
                  src={Images?.image_1}
                  onLoad={() => { setImgSkell2(false) }}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Div
                sx={{
                  gap: 1.5,
                  height: "100%",
                  display: "flex",
                  maxWidth: "450px",
                  flexDirection: "column",
                  justifyContent: "center",
                  mx: { xs: "auto", md: "initial" },
                  textAlign: { xs: "center", md: "start" }
                }}
              >
                <Typography
                  component="h5"
                  className="sec-title !md:text-center !text-start !mb-0"
                  sx={{ textAlign: { xs: "center !important", md: "start !important" } }}
                >Calculator: Monthly Accounting Price
                </Typography>
                <Para sx={{ fontSize: "14px" }}>In today's fast-paced business environment, managing finances effectively is crucial for success. However, handling complex accounting tasks in-house can be both time-consuming and costly.</Para>
                <Para sx={{ fontSize: "14px" }}>That's where accounting outsourcing comes in. By partnering with our expert team, you can focus on what you do best—growing your business—while we take care of your accounting needs.</Para>
              </Div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Experience */}
      <Box as="section" sx={{ py: 3, bgcolor: "#fde6e3" }}>
        <Container maxWidth="xl">
          <Typography
            component="h5"
            className="sec-title"
          >Why Outsource Your Accounting?
          </Typography>
          <Box>
            <Grid container spacing={2.5}>
              {experiences?.map((experience, i) => (
                <Grid key={i} size={{ xs: 12, md: 4 }}>
                  <Box
                    sx={{
                      p: 2,
                      height: "100%",
                      color: "black",
                      cursor: "pointer",
                      bgcolor: "#fef3f2",
                      borderRadius: "10px",
                      transitionDuration: "350ms",
                      ":hover": {
                        color: "White",
                        bgcolor: "#e95d4a",
                        transform: "scale(1.008)",
                        boxShadow: "0px 0px 20px 0px #b4332157"
                      }
                    }}>
                    <Para sx={{ fontWeight: "500" }}>{experience?.title}</Para>
                    <Para sx={{ fontSize: "14px", fontWeight: "400" }}>{experience?.content}</Para>
                  </Box>
                </Grid>
              ))
              }
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Services */}
      <Div as="section" sx={{ py: 3 }}>
        <Container maxWidth="xl">
          <Div sx={{ mb: 3, maxWidth: "600px", mx: "auto" }}>
            <Typography
              component="h5"
              mb="16px !important"
              className="sec-title"
            >Our Comprehensive Services
            </Typography>
            <Para sx={{ fontSize: "14px", maxWidth: "400px", mx: "auto", textAlign: "center" }}>Our outsourcing services cover a wide range of functions, tailored to meet your unique needs</Para>
          </Div>

          <Grid container spacing={2}>
            {services?.map((service, i) => (
              <Grid key={i} size={{ xs: 12, md: 4 }}>
                <Div
                  sx={{
                    p: 1,
                    gap: "6px",
                    display: "flex",
                    borderRadius: "8px",
                    flexDirection: "column",
                    boxShadow: "0 0 20px 0 #00000017"
                  }}
                >
                  <Div
                    sx={{
                      width: "40px",
                      height: "40px",
                      display: "grid",
                      bgcolor: "#FDE7E4",
                      borderRadius: "8px",
                      placeContent: "center"
                    }}
                  >
                    <Image
                      sx={{
                        height: "25px",
                      }}
                      alt="icon"
                      src={Images[`icon_${i + 1}`]}
                    />
                  </Div>
                  <Para sx={{ fontWeight: "500" }}>{service?.title}</Para>
                  <Para sx={{ fontSize: "14px" }}>{service?.content}</Para>
                </Div>
              </Grid>
            ))
            }
          </Grid>
        </Container>
      </Div>

      {/* Why us */}
      <Div as="section" sx={{ py: 3, fontFamily: "Jost" }}>
        <Container maxWidth="xl">
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Div sx={{ height: "350px" }}>
                {imgSkell3 &&
                  <Skeleton
                    sx={{
                      height: "354px",
                      transform: "unset"
                    }}
                  />
                }
                <Image
                  alt="img"
                  src={Images?.image_2}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: !imgSkell3 ? "block" : "none"
                  }}
                  onLoad={() => { setImgSkell3(false) }}
                />
              </Div>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography
                component="h5"
                className="sec-title !text-center !md:text-start"
              >Why Choose Us?
              </Typography>
              <Div sx={{ bgcolor: "#F4EBE9", p: 1, borderRadius: "8px", mb: 2 }}>
                <Para sx={{ fontWeight: "600", fontSize: "14px" }}>Tailored Solutions</Para>
                <Para sx={{ fontSize: "13px" }}>We understand that each business is unique. Our team works closely with you to develop customized accounting solutions that align with your specific needs and goals.</Para>
              </Div>
              <Div sx={{ bgcolor: "#F4EBE9", p: 1, borderRadius: "8px", mb: 2 }}>
                <Para sx={{ fontWeight: "600", fontSize: "14px" }}>Cutting-Edge Technology</Para>
                <Para sx={{ fontSize: "13px" }}>We leverage the latest accounting software and technology to provide you with efficient, secure, and seamless services</Para>
              </Div>
              <Div sx={{ bgcolor: "#F4EBE9", p: 1, borderRadius: "8px" }}>
                <Para sx={{ fontWeight: "600", fontSize: "14px" }}>Commitment to Excellence</Para>
                <Para sx={{ fontSize: "13px" }}>Our dedication to delivering exceptional service is unwavering. We strive to exceed your expectations and build long-term partnerships based on trust and mutual success.</Para>
              </Div>
            </Grid>
          </Grid>
        </Container>
      </Div>

      {/* Get started */}
      <Div as="section" sx={{ py: 3 }}>
        <Container maxWidth="xl">
          <Div
            sx={{
              zIndex: "0",
              position: "relative",
              backgroundSize: "cover",
              backgroundColor: "#000000ad",
              backgroundImage: `url("${Images?.image_3}")`,
              clipPath: "polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0 85%)",
              ":after": {
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                content: "''",
                position: "absolute",
                backgroundColor: "#000c"
              }
            }}>
            <Div
              sx={{
                py: 5,
                zIndex: 10,
                gap: "10px",
                display: "flex",
                alignItems: "center",
                position: "relative",
                flexDirection: "column",
              }}>
              <Para sx={{ maxWidth: "500px", color: "white", fontWeight: "300", mx: "auto", fontSize: "14px", textAlign: "center" }}>Transform your business's financial management with our expert accounting outsourcing services. Contact us today to schedule a consultation and discover how we can help you achieve financial clarity and peace of mind.</Para>

              <Div>
                <Button
                  LinkComponent="a"
                  href="#calculater"
                  variant="contained"
                  sx={{
                    bgcolor: "#d6422e",
                    borderRadius: "50px",
                    textTransform: "capitalize",
                  }}
                >get started today
                </Button>
              </Div>
            </Div>
          </Div>
        </Container>
      </Div>

      {/* Testimonials */}
      <Div as="section" sx={{ py: 3 }}>
        <Container maxWidth="xl">
          <Div
            sx={{
              p: 4,
              pb: 7,
              zIndex: 0,
              position: "relative",
              filter: "url(#round)",
              ":after": {
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                zIndex: "1",
                content: "''",
                bgcolor: "#F0F0F0",
                position: "absolute",
                clipPath: {
                  xs: "polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0 90%)",
                  md: "polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0 85%)",
                },
              }
            }}>
            <Div sx={{ position: "relative", zIndex: "2" }}>
              <Typography
                component="h5"
                className="sec-title"
              >Testimonials
              </Typography>
              <Div
                sx={{
                  gap: "8px",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: { xs: "block", sm: "flex" },
                }}
              >
                <Div>
                  <Para sx={{ fontSize: "14px !important" }}>
                    <MuiIcons.Star className="!text-[16px] text-[#FCC812]" />
                    <MuiIcons.Star className="!text-[16px] text-[#FCC812]" />
                    <MuiIcons.Star className="!text-[16px] text-[#FCC812]" />
                    <MuiIcons.Star className="!text-[16px] text-[#FCC812]" />
                    <MuiIcons.Star className="!text-[16px] text-[#FCC812]" />
                  </Para>
                  <Para sx={{ color: "#666666", fontSize: "14px" }}>
                    <Span sx={{ fontWeight: "600", color: "black", fontSize: "24px" }}>4.8&nbsp;</Span>
                    /5.0
                  </Para>
                </Div>

                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Image
                    alt="img"
                    src={Images["swastik"]}
                    sx={{
                      zIndex: 5,
                      width: "40px",
                      height: "40px",
                      borderRadius: "40px",
                      position: "relative",
                    }}
                  />
                  <Div
                    sx={{
                      px: 2,
                      ml: -1.5,
                      zIndex: 1,
                      bgcolor: "white",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  >
                    <Para>
                      <Span sx={{ fontWeight: "700", fontSize: "20px" }}>24K</Span>
                      &nbsp;Happy Customer
                    </Para>
                  </Div>
                </Div>
              </Div>

              <Div sx={{ py: 2, fontFamily: "Jost" }}>
                <Grid container spacing={4}>
                  {testimonials.map((testimonial, i) => (
                    <Grid key={i} size={{ xs: 12, md: 6 }}>
                      <Div sx={{ display: "flex", gap: "8px" }}>
                        <Div
                          sx={{
                            height: "50px",
                            minHeight: "50px",
                            overflow: "hidden",
                            aspectRatio: "1/1",
                            borderRadius: "50px",
                          }}
                        >
                          <Image
                            sx={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="img"
                            src={Images[testimonial?.image] || Images?.image_4}
                          />
                        </Div>

                        <Div>
                          <Para sx={{ fontWeight: "600" }}>{testimonial?.name}</Para>
                          {/* <Para sx={{ fontWeight: "500", fontSize: "14px" }}>CMO</Para> */}
                          <Para sx={{ fontSize: "13px" }}>{testimonial?.description}</Para>
                        </Div>
                      </Div>
                    </Grid>
                  ))
                  }
                </Grid>
              </Div>
            </Div>
          </Div>
        </Container>
      </Div>

      {/* Subscribe */}
      <Div as="section" sx={{ pb: 6, pt: 3 }}>
        <Container>
          <Div sx={{ mx: "auto", maxWidth: "600px" }}>
            <Typography
              component="h5"
              className="sec-title"
              sx={{ mx: "auto", maxWidth: "450px" }}
            >Expert accounting and bookkeeping services for your business
            </Typography>
            <Box
              sx={{
                p: "2px",
                gap: "4px",
                width: "100%",
                display: "flex",
                borderRadius: "4px",
                border: "1px solid gray",
              }}
              component="form"
              onSubmit={handleSubscription}
            >
              <Div sx={{ flexGrow: 1 }}>
                <Input
                  sx={{
                    p: "0 4px",
                    width: "100%",
                    height: "100%",
                    display: "block",
                    borderRadius: "4px",
                    ":after": { display: "none" },
                    ":before": { display: "none" },
                    "input": {
                      p: 0,
                      height: "100%",
                      fontFamily: "Poppins",
                    }
                  }}
                  type="text"
                  name="email"
                />
              </Div>
              <Button
                sx={{
                  py: "4px",
                  minWidth: "140px",
                  textTransform: "capitalize",
                  fontFamily: "Jost",
                  fontWeight: "400",
                  borderRadius: "6px",
                  bgcolor: "#D6422E"
                }}
                variant="contained"
                type="submit"
              >contact us
              </Button>
            </Box>
          </Div>
        </Container>
      </Div>

      <svg style={{ visibility: "hidden", position: "absolute" }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
          </filter>
        </defs>
      </svg>
    </Fragment >
  )
}

export default Home