
export const services = [
  { title: "Expert Accounting", content: "Accurate and up-to-date record-keeping to maintain Accounting clarity." },
  { title: "Technical Controller", content: "Deep analysis of your financial with process control and GAAP adherence " },
  { title: "Master CFO", content: "Expert forecasting and budget planning services, ensuring financial strategy alignment." },
  { title: "Sure Compliances", content: "We ensure state and federal tax compliance, including 1099 filings." },
  { title: "Digital Reporting", content: "Detailed and insightful reports that help you understand your business's financial health." },
  { title: "Personnel Payroll", content: "Run payroll and employee state registrations." },
]

export const experiences = [
  { title: "Cost Efficiency", content: "Outsourcing your accounting functions allows you to save on the costs associated with hiring, training, and retaining full-time staff. You pay only for the services you need, which translates into significant savings for your business." },
  { title: "Access to Expertise", content: "Our team comprises seasoned accounting professionals with years of experience across various industries. We stay updated with the latest regulations and best practices, ensuring your business is always compliant and ahead of the curve." },
  { title: "Enhanced Focus on Core Business Activities:", content: "By entrusting your accounting tasks to us, you free up valuable time and resources to focus on core business activities. This shift allows you to concentrate on strategic initiatives that drive growth and profitability." },
  { title: "Scalability and Flexibility", content: "As your business grows, your accounting needs will evolve. Our outsourcing services are highly scalable, providing you with the flexibility to adjust services according to your changing requirements without the hassle of hiring new staff." },
  { title: "Improved Accuracy and Compliance:", content: "With our expertise and advanced technology, we minimize the risk of errors and ensure compliance with all relevant financial regulations. This accuracy is vital for making informed business decisions and maintaining a strong financial standing." },
]

export const industries = [
  { name: "saas", rate: 1.25, minPrice: 129 },
  { name: "Consumer Goods", rate: 1.5, minPrice: 149 },
  { name: "Non-Profit", rate: 1.25, minPrice: 129 },
  { name: "Health Care", rate: 1.25, minPrice: 129 },
  { name: "Media/ Entertainment", rate: 1.25, minPrice: 129 },
  { name: "others", rate: 1.25, minPrice: 129 },
]

export const testimonials = [
  {
    image: "",
    name: "Xpia",
    description: "Working with Experts Finacc has transformed our financial management. Their team is incredibly knowledgeable and responsive, making complex accounting tasks simple and stress-free. We’ve seen significant improvements in our cash flow and financial planning. Highly recommend! ",
  },
  {
    image: "swastik",
    name: "Swastik",
    description: "The expertise and support we received from Experts Finacc have been invaluable. They helped us streamline our accounting processes and provided insights that have driven our growth.",
  },
]