import Images from "../../assets/images";

export const services = [
  {
    title: "Expert Accounting",
    image: Images?.service_1,
    keyPoints: [
      {
        key: "Vendor Management, Customer Management:",
        content: "New vendor/customer Setup, recording bills/invoices, open AP Aging/AR Aging.",
      },
      {
        key: "Bank/Credit Card:",
        content: "Record transactions, reconciliation, receipts matching.",
      },
      {
        key: "Financials:",
        content: "Preparation of accurate financial statements, balance sheets, income statements, and cash flow reports.",
      },
      {
        key: "Process Documentation:",
        content: "Process flowcharts for your all processes including frequency and responsibility. ",
      },
    ]
  },
  {
    title: "Technical Controller",
    image: Images?.service_2,
    keyPoints: [
      {
        key: "Financial Oversight:",
        content: "Overseeing the preparation of financial statements, reports, and records to ensure accuracy",
      },
      {
        key: "Regulatory Compliance:",
        content: "Ensuring all financial practices comply with relevant regulations, such as GAAP, IFRS, and local tax laws.",
      },
      {
        key: "Account Management:",
        content: "One point of contact for you to discuss everything. ",
      },
      {
        key: "Inventory Management:",
        content: "To manage the inventory accounting as a critical part of the process.",
      },
    ]
  },
  {
    title: "Master CFO",
    image: Images?.service_3,
    keyPoints: [
      {
        key: "Strategic Financial Leadership:",
        content: "Leading the development and execution of financial strategies to support long-term business growth and profitability.",
      },
      {
        key: "Financial Planning and Analysis (FP&A):",
        content: "Overseeing budgeting, forecasting, and financial modeling to provide data-driven insights for decision-making.",
      },
      {
        key: "Cash Flow Management:",
        content: "Monitoring and optimizing cash flow, ensuring the organization has sufficient liquidity for operations and growth.",
      },
      {
        key: "Risk Management:",
        content: "Identifying, assessing, and mitigating financial risks, including market risks, operational risks, and liquidity risks.",
      },
    ]
  },
  {
    title: "Sure Compliances",
    image: Images?.service_4,
    keyPoints: [
      {
        key: "Regulatory Adherence:",
        content: "Prepare compliance calendar based on different state compliances and manage the deadlines.",
      },
      {
        key: "Tax Filings:",
        content: "Filing federal and state tax returns based on the company type.",
      },
      {
        key: "1099 Filings:",
        content: "Filing 1099 as per IRS threshold based on the entity type eligibility.",
      },
      {
        key: "Other State Compliances:",
        content: "SOI Filings, DE filings etc.",
      },
    ]
  },
  {
    title: "Personnel Payroll",
    image: Images?.service_5,
    keyPoints: [
      {
        key: "Payroll:",
        content: "Managing the accurate and timely processing of employee payroll, including calculations for wages, salaries, bonuses, and deductions.",
      },
      {
        key: "Offer Letter and I-9 Verification:",
        content: "HR support including offer letter process processing and I-9 Verification.",
      },
      {
        key: "HRBP Support:",
        content: "Human Resource Business Partner (HRBP) to support for creating, updating, and enforcing company policies and procedures related to compliance, and providing training to employees to ensure understanding and adherence to these policies.",
      },
      {
        key: "Employee Nexus State Registrations:",
        content: <>State registrations based on employee&apos;s locations.</>,
      },
    ]
  },
  {
    title: "Digital Reports and Data Migration",
    image: Images?.service_6,
    keyPoints: [
      {
        key: "Data Collection and Integration:",
        content: "Gathering and integrating data from various digital sources, such as CRM systems, web analytics, and financial databases, to create comprehensive reports.",
      },
      {
        key: "Report Generation:",
        content: "Using digital tools and software to generate reports, including dashboards, visualizations, and performance metrics that provide insights into key business indicators.",
      },
      {
        key: "Data Accuracy and Quality:",
        content: "Ensuring the accuracy and quality of the data used in digital reports, including validation, cleansing, and verification processes.",
      },
      {
        key: "Data Migration:",
        content: "Migrate data from one accounting software to another (Trial Balance basis or Transactional level)",
      },
    ]
  },
]