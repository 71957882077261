import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  toast: {},
  close: false,
  show: false,
}
const other = createSlice({
  name: "other",
  initialState,
  reducers: {
    // Dialogs
    showDialog: (state, { payload }) => {
      state.show = true
      state.type = payload
    },
    showDetailDialog: (state, { payload }) => {
      state.show = true
      state.type = "GetDetails"
      state.industryData = payload
    },
    showDetailDialogPricing: (state, { payload }) => {
      state.show = true
      state.industryData = payload
      state.type = "GetDetailsPeicing"
    },
    hideDialog: (state) => {
      state.show = false
      state.type = ""
    },

    // Toaster
    toastError: (state, { payload }) => {
      state.toast.show = true
      state.toast.msg = payload
      state.toast.severity = "error"
    },
    toastSuccess: (state, { payload }) => {
      state.toast.show = true
      state.toast.msg = payload
      state.toast.severity = "success"
    },
    toastHide: (state) => {
      state.toast = {}
    }
  },
})

export const {
  toastSuccess, toastHide,
  showDialog, hideDialog, toastError,
  showDetailDialog, showDetailDialogPricing
} = other.actions
export default other.reducer