import { Container, Link, styled, Typography } from "@mui/material"
import { Link as RLink } from "react-router-dom"

const Div = styled("div")(() => ({}))
function Footer() {
  const year = new Date().getFullYear()

  return (
    <Div
      as="footer"
      sx={{
        py: 1,
        color: "white",
        bgcolor: "#243039",
      }}>
      <Container maxWidth="xl">
        <Div
          sx={{
            textAlign: "center",
            position: "relative",
            justifyContent: "space-between",
            display: { xs: "block", sm: "flex", md: "block" }
          }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Jost",
              textAlign: "center",
            }}
          >&copy; {year} Expert FinAcc. All rights reserved. Expert FinAcc
          </Typography>
          <Link
            sx={{
              right: "0",
              top: "50%",
              color: "white",
              textDecorationColor: "white",
              transform: { md: "translateY(-50%)" },
              position: { xs: "static", md: "absolute" },
            }}
            component={RLink}
            to="mailto:info@expertsfinacc.com"
          >info@expertsfinacc.com
          </Link>
        </Div>
      </Container>
    </Div>
  )
}

export default Footer