import { useSelector } from "react-redux"
import { Dialog } from "@mui/material"
import GetDetails from "./dialogs/GetDetails"
import GetDetailsPeicing from "./dialogs/GetDetailsPeicing"

function DialogContainer() {
  const { show, type } = useSelector(state => state.otherSlice)

  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="xs"
      scroll="paper"
      id="#MuiDialog"
      sx={{
        "& .MuiBackdrop-root": { backdropFilter: "blur(1px)", backgroundColor: "#00000050" },
      }}
    >
      {type === "GetDetails" && <GetDetails />}
      {type === "GetDetailsPeicing" && <GetDetailsPeicing />}
    </Dialog>
  )
}

export default DialogContainer