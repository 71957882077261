import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { BASEURI } from "../../utils/helper"
import { hideDialog, toastError, toastSuccess } from "./other.slice"
import axios from "axios"

export const getDetailsReq = createAsyncThunk("getDetailsReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/form-submit`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch(hideDialog())
      if (res?.status === 200) {
        sessionStorage.setItem("varified", true)
        // dispatch(toastSuccess("An email sent to your E-mail!"))
        return res?.status
      }
    }).catch((err) => {
      if (err?.status === 400) {
        if (err?.response?.data?.message) {
          dispatch(toastError(err?.response?.data?.message))
          return rejectWithValue(err)
        } else {
          dispatch(toastError("Something went wrong!"))
          return rejectWithValue(err)
        }
      }
      dispatch(hideDialog())
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const getDetailsPricingReq = createAsyncThunk("getDetailsPricingReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/form-submit/pricing-plan`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch(hideDialog())
      if (res?.status === 200) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.status
      }
    }).catch((err) => {
      if (err?.status === 400) {
        if (err?.response?.data?.message) {
          dispatch(toastError(err?.response?.data?.message))
          return rejectWithValue(err)
        } else {
          dispatch(toastError("Something went wrong!"))
          return rejectWithValue(err)
        }
      }
      dispatch(hideDialog())
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const getinTouchReq = createAsyncThunk("getDetailsPricingReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/form-submit/get-in-touch`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch(hideDialog())
      if (res?.status === 200) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.status
      }
    }).catch((err) => {
      if (err?.status === 400) {
        if (err?.response?.data?.message) {
          dispatch(toastError(err?.response?.data?.message))
          return rejectWithValue(err)
        } else {
          dispatch(toastError("Something went wrong!"))
          return rejectWithValue(err)
        }
      }
      dispatch(hideDialog())
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const handleSubscribeReq = createAsyncThunk("getDetailsPricingReq", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURI}/form-submit/subscribe`, data, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((res) => {
      dispatch(hideDialog())
      if (res?.status === 200) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.status
      }
    }).catch((err) => {
      if (err?.status === 400) {
        if (err?.response?.data?.message) {
          dispatch(toastError(err?.response?.data?.message))
          return rejectWithValue(err)
        } else {
          dispatch(toastError("Something went wrong!"))
          return rejectWithValue(err)
        }
      }
      dispatch(hideDialog())
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {}
const mail = createSlice({
  name: "mail",
  initialState,
  reducers: {
    setStatus: (state) => {
      state.status = 200
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDetailsReq.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDetailsReq.fulfilled, (state, { payload }) => {
      delete state.loading
      state.status = payload
    })
    builder.addCase(getDetailsReq.rejected, (state) => {
      delete state.loading
    })

    builder.addCase(getDetailsPricingReq.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDetailsPricingReq.fulfilled, (state, { payload }) => {
      delete state.loading
    })
    builder.addCase(getDetailsPricingReq.rejected, (state) => {
      delete state.loading
    })

  }
})

export const { setStatus } = mail.actions
export default mail.reducer