import { styled } from "@mui/material"
import Header from "./Header"
import { Outlet } from "react-router-dom"
import Footer from "./Footer"
import DialogContainer from "../common/DialogContainer"
import Toaster from "../common/Toaster"

const Div = styled("div")(() => ({}))
function Layout() {

  return (
    <Div
      sx={{
        display: "grid",
        minHeight: "100vh",
        gridTemplateRows: "auto 1fr auto"
      }}
    >
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />

      <DialogContainer />
      <Toaster />
    </Div>
  )
}

export default Layout