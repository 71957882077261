import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'

const MuiIcons = {
  Star: StarRoundedIcon,
  Menu: MenuRoundedIcon,
  Close: ClearRoundedIcon,
  Calculater: CalculateRoundedIcon,
  FillCheck: CheckCircleRoundedIcon,
  RightArrowDbl: KeyboardDoubleArrowRightRoundedIcon,
}

export default MuiIcons