import { Box, Container, Grid2 as Grid, Skeleton, styled, Typography } from "@mui/material"
import Images from "../assets/images"
import { Fragment, useState } from "react"
import { services } from "../constant/frontend/services"

const Image = styled("img")(() => ({}))
const Paragraph = styled("p")(() => ({}))
function Services() {
  const [state, setState] = useState(false)

  return (
    <Fragment>
      <Box as="section">
        <Box
          sx={{
            zIndex: "0",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#000000ad",
            backgroundImage: `url("${Images?.image_5}")`,
            clipPath: {
              xs: "polygon(0% 0%, 100% 0%, 100% 80%, 50% 100%, 0 80%)",
              sm: "polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0 85%)",
              md: "polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0 75%)",
            },
            ":after": {
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              content: "''",
              position: "absolute",
              backgroundColor: "#00000080"
            }
          }}>
          <Box
            sx={{
              zIndex: 10,
              gap: "10px",
              display: "grid",
              position: "relative",
              placeContent: "center",
              minHeight: { xs: "250px", md: "350px" },
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "32px",
                fontWeight: "700",
                fontFamily: "Poppins",
              }}
              component="h2"
            >Our Services
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box as="section" sx={{ py: 3 }}>
        <Container maxWidth="xl">
          <Box sx={{ mb: 3, maxWidth: "600px", mx: "auto" }}>
            <Typography
              component="h5"
              mb="16px !important"
              className="sec-title"
            >Our Accounting & Financial Services
            </Typography>
            <Paragraph sx={{ fontSize: "14px", maxWidth: "400px", mx: "auto", textAlign: "center" }}>Our outsourcing services cover a wide range of accounting functions, tailored to meet your unique needs</Paragraph>
          </Box>
        </Container>
      </Box>

      <Box as="section" sx={{ py: 3 }}>
        <Container maxWidth="xl">
          <Grid container spacing={{ xs: 4, sm: 5, md: 8 }}>
            {services?.map((service, i) => (
              <Grid key={i} size={12}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12, md: 6 }} sx={{ order: { md: i % 2 === 0 ? "1" : "2" } }}>
                      {state &&
                        <Skeleton
                          sx={{
                            height: "354px",
                            transform: "unset"
                          }}
                        />
                      }
                      <Image
                        alt="service-img"
                        src={service?.image}
                        sx={{
                          mx: "auto",
                          maxHeight: { xs: "300px", md: "100%" },
                          display: !state ? "block" : "none"
                        }}
                        onLoad={() => { setState(false) }}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }} sx={{ order: { md: i % 2 === 0 ? "2" : "1" } }}>
                      <Box
                        sx={{
                          gap: 2.5,
                          height: "100%",
                          display: "flex",
                          maxWidth: "450px",
                          flexDirection: "column",
                          justifyContent: "center",
                          mx: { xs: "auto", md: "initial" },
                          // textAlign: { xs: "center", md: "start" }
                        }}
                      >
                        <Typography
                          component="h5"
                          className="sec-title !text-start !mb-0"
                        >{service?.title}
                        </Typography>
                        {service?.keyPoints?.map((item, j) => (
                          <Paragraph key={j} sx={{ fontSize: "14px" }}>
                            <strong>{item?.key}</strong>{item?.content}
                          </Paragraph>
                        ))
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))
            }
          </Grid>
        </Container>
      </Box>

      <Box as="section" sx={{ mt: 5, pb: 6, mb: { xs: 3, md: 8 } }}>
        <Container maxWidth="xl">
          <Box sx={{ mb: 5, maxWidth: "600px", mx: "auto" }}>
            <Typography
              component="h5"
              mb="16px !important"
              className="sec-title"
            >Experts in tools
            </Typography>
          </Box>

          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            {new Array(7).fill(0)?.map((_, i) => (
              <Grid key={i} size={{ xs: 6, sm: 4, lg: 3 }}>
                <Box
                  sx={{
                    display: "grid",
                    p: { xs: 2, lg: 3 },
                    borderRadius: "8px",
                    placeContent: "center",
                    minWidth: { md: "200px" },
                    boxShadow: "0px 2px 15px 0px #0000001f",
                  }}
                >
                  <Image
                    alt="tool-img"
                    src={Images[`tool_${i + 1}`]}
                    sx={{
                      height: { xs: "40px", lg: "50px" },
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Grid>
            ))
            }
          </Grid>
        </Container>
      </Box>
    </Fragment>
  )
}

export default Services